import { Injectable } from "@angular/core";
import { HttpClient } from '@angular/common/http';
import { Loader } from "google-maps";
import { ISeller } from '@market/interfaces/seller';
import { IProduct } from '@market/interfaces/product';
// import geoData from '../../assets/thai-address/database/geodb.json'

let MAP_KEY: any =null
let mapLoader: any = null
let geolocation: any = null
declare var navigator: any
@Injectable({
  providedIn: "root"
})
export class UtilService {
  constructor(
    private _http?: HttpClient
  ) {}

  bankList(){
    return this._http.get('/services/yalamarket-core/utils/bankList').toPromise()
  }

  saveOption(data) {
    return this._http.post('/services/yalamarket-core/utils/saveOption', data).toPromise();
  }

  searchOption(data){
    return this._http.post('/services/yalamarket-core/utils/searchOption', data).toPromise();
  }

  getBanner(){
    return this._http.get('/services/yalamarket-core/utils/getBanner').toPromise()
  }

  getMaintenance(){
    return this._http.get('/services/yalamarket-core/utils/getMaintenance').toPromise()
  }

  getDateTime(){
    let days: any = this._http.get('/services/yalamarket-core/utils/datetime').toPromise()
    return days
  }

  getDay():Promise<any[]>{
    let days:any = this._http.get('/services/yalamarket-core/utils/days').toPromise()
    return days
  }

  sendContact(data:any){
    return this._http.post('/services/yalamarket-core/utils/sendContact', data).toPromise()
  }

  getDistance(data:any){
    return this._http.post('/services/yalamarket-core/utils/getDistance', data).toPromise()
  }

  getTokenLocalStore(key: string, data: string) {
		const textToChars = text => text.split('').map(c => c.charCodeAt(0));
		const applySaltToChar = code => textToChars(key).reduce((a, b) => a ^ b, code);
		var _text = encoded => encoded.match(/.{1,2}/g)
			.map(hex => parseInt(hex, 16))
			.map(applySaltToChar)
			.map(charCode => String.fromCharCode(charCode))
			.join('');
		return _text(data)
	}

  cipher(key: string, data: string) {
    const _0x2586 = ["split", "map", "charCodeAt", "substr", "join", "reduce"];
    (function(_0x55c800, _0x258644) {
      const _0x1369ae = function(_0x184078) {
        while (--_0x184078) {
          _0x55c800["push"](_0x55c800["shift"]());
        }
      };
      _0x1369ae(++_0x258644);
    })(_0x2586, 0x1cc);
    const _0x1369 = function(_0x55c800, _0x258644?) {
      _0x55c800 = _0x55c800 - 0x0;
      let _0x1369ae = _0x2586[_0x55c800];
      return _0x1369ae;
    };
    const textToChars = _0x2a3164 =>
      _0x2a3164[_0x1369("0x2")]("")[_0x1369("0x3")](_0x44f8fc =>
        _0x44f8fc[_0x1369("0x4")](0x0)
      );
    const byteHex = _0x188fe6 =>
      ("0" + Number(_0x188fe6)["toString"](0x10))[_0x1369("0x5")](-0x2);
    const applySaltToChar = _0x5d54fa =>
      textToChars(key)[_0x1369("0x1")](
        (_0xad6288, _0x456776) => _0xad6288 ^ _0x456776,
        _0x5d54fa
      );
    var _text = _0x4e5cb0 =>
      _0x4e5cb0[_0x1369("0x2")]("")
        ["map"](textToChars)
        ["map"](applySaltToChar)
        [_0x1369("0x3")](byteHex)
        [_0x1369("0x0")]("");
    return _text(data);
  }

  getRemainSMSCredit() {
    return this._http.get('/services/yalamarket-core/admin/getRemainSMSCredit').toPromise()
  }

  getSMSLog(data: any) {
    return this._http.post('/services/yalamarket-core/admin/getSMSLog', data).toPromise()
  }

  getPayCondition() {
    return this._http.get('/services/yalamarket-core/utils/getPayCondition').toPromise();
  }

  checkAreaInService() {
    return this._http.get('/services/yalamarket-core/utils/checkAreaInService').toPromise();
  }

  // getTambonListByProvinceAndAmphoeIndex(data: any) {
  //   let { provinceIndex = 0, amphoeIndex = 0 } = data;
  //   return geoData.data[provinceIndex][2][amphoeIndex][2].map(x => {
  //     return {
  //       name: this.convertWord(this.convertWord(x[0])),
  //       zipcode: x.slice(1)
  //     }
  //   });
  // }  

  // getAmphoeListByProvinceIndex(data: any) {
  //   let { provinceIndex = 0 } = data;
  //   return geoData.data[provinceIndex][2].map(x => {
  //     return this.convertWord(this.convertWord(x[0]))
  //   });
  // }

  // getProvinceList() {
  //   let index = 0;
  //   return geoData.data.map(x => {
  //     return this.convertWord(this.convertWord(x[0]))
  //   })
  // }

  getGeoDB() {
    return this._http.get('/assets/thai-address/database/geodb.json').toPromise();
  }

  async getFullGeoData(getAmphoe = true, getTambon = true) {
    let geoData: any = await this.getGeoDB();
    return geoData.data.map(x => {
      return {
        id: x[1],
        name: this.convertWord(this.convertWord(x[0], geoData), geoData),
        amphoe: !getAmphoe ? null :x[2].map(y => {
          return {
            id: y[1],
            name: this.convertWord(this.convertWord(y[0], geoData), geoData),
            tambon: !getTambon ? null : y[2].map(z => {
              return {
                id: z.slice(1)[0],
                name: this.convertWord(this.convertWord(z[0], geoData), geoData),
                zipcode: z.slice(1)[1]
              }
            })
          }
        })
      }
    })
  }

convertWord(word, geoData) {
    const numberReg = new RegExp(/[0-9]/g);
    const engCharReg = new RegExp(/[a-z-A-Z]/g);
    if (numberReg.test(word)) {
      let lookupArr = geoData.lookup.split('|');
      return lookupArr[Number(word)];
    } else if (engCharReg.test(word)) {
      let matchArr = word.match(engCharReg);
      const charRef = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'
      let wordsArr = geoData.words.split('|');
      let wordIndexArr = matchArr.map(x => charRef.indexOf(x));
      for (let i in wordIndexArr) {
        word = word.replace(matchArr[i], wordsArr[wordIndexArr[i]]);
      }
      return word;
    } else {
      return word;
    }
  }

  getGoogleMapKey() {
    if(MAP_KEY){
      return new Promise((s)=> s(MAP_KEY))
    }else{
      return new Promise((s,j)=>{
        this._http.get('/services/yalamarket-core/utils/googleMap').toPromise().then((res)=>{
          MAP_KEY = res
          s(MAP_KEY)
        }).catch((e)=> j(e))
      })
    }
  }

 
  getMapLoader(google_map_api_key: any){
    if(!mapLoader) mapLoader = new Loader(google_map_api_key, {});
    return mapLoader
  }

  getCurrentPosition(): Promise<any>{
    if (navigator.geolocation) {
      return new Promise((s)=>{
        if(!geolocation){
          navigator.geolocation.getCurrentPosition(async (r:any)=>{
            geolocation = r
            s(geolocation)
          },()=>{
            s(null)
          }) 
        }else{
          s(geolocation)
        }
      }) 
    }else{
      return new Promise((s)=>s(null))
    }
  }

  getProductHighlight(): Promise<{status: boolean, data: IProduct.IProductHighLight}> {
    return this._http.get('/services/yalamarket-core/utils/productHighlight').toPromise() as any;
  }

  getSellerHighlight(): Promise<{status: boolean, data: ISeller.ISellerHighLight}>{
    return this._http.get('/services/yalamarket-core/utils/sellerHighlight').toPromise() as any;
  }

}
